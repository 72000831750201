import { isEqual } from 'lodash';
import { useEffect } from 'react';
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import * as uuid from 'uuid';

import { pagesState } from './page';
import { sessionIdState } from './sessionId';
import { SearchResult, SearchResultSection } from './types';
import { searchQueryState, useWaldoSearchQuery } from './waldoQuery';

/**
 * Search Query ID state
 *
 * - Uniquely identifies a search query + parameters without Waldo filters like xray, skim
 */
export const searchQueryIdState = selector<string>({
  key: 'searchQueryIdState',
  get: ({ get }) => {
    const searchQuery = get(searchQueryState);
    const sessionId = get(sessionIdState);
    return uuid.v5(JSON.stringify(searchQuery), sessionId);
  },
});

export const useSearchQueryId = (): string =>
  useRecoilValue(searchQueryIdState);

/**
 * Hovered Search Result state
 */

export const hoveredSearchResultState = atom<SearchResult | null>({
  key: 'hoveredSearchResult',
  default: null,
});

export const useHoveredSearchResult = (): SearchResult | null =>
  useRecoilValue(hoveredSearchResultState);

export const useSetHoveredSearchResult =
  (): SetterOrUpdater<SearchResult | null> =>
    useSetRecoilState(hoveredSearchResultState);

export const isHoveredSearchResultSelector = selectorFamily<
  boolean,
  Readonly<SearchResult>
>({
  key: 'isHoveredSearchResult',
  get:
    (searchResult: SearchResult) =>
    ({ get }) => {
      const hovered = get(hoveredSearchResultState);
      return isEqual(hovered, searchResult);
    },
});

export const useIsHoveredSearchResult = (searchResult: SearchResult): boolean =>
  useRecoilValue(isHoveredSearchResultSelector(searchResult));

/**
 * Results state
 */
export const resultsState = selector<SearchResult[]>({
  key: 'resultsState',
  get({ get }) {
    const pages = get(pagesState);
    return pages.flatMap((page) => page.results);
  },
});

export const useSearchResults = (): SearchResult[] =>
  useRecoilValue(resultsState);

export const showSearchResultsState = atom<boolean>({
  key: 'showSearchResultsState',
  default: true,
});

export const useShowSearchResults = (): boolean =>
  useRecoilValue(showSearchResultsState);

export const useSetShowSearchResults = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(showSearchResultsState);

/**
 * Provides information on whether or not the search query has filtered results.
 * E.g. with datapoints filter turned on.
 */
const hasFilteredResultsState = atomFamily<boolean, SearchResultSection>({
  key: 'filteredResultsState',
  default: false,
});

export const useSetHasFilteredResults = () =>
  useRecoilCallback(
    ({ set }) =>
      (Section: SearchResultSection, value: boolean) => {
        set(hasFilteredResultsState(Section), value);
      },
  );

export const useHasFilteredResults = (
  section: SearchResultSection,
): boolean => {
  const query = useWaldoSearchQuery();
  const setFilteredResults = useSetHasFilteredResults();

  useEffect(() => {
    setFilteredResults(SearchResultSection.search, false);
    setFilteredResults(SearchResultSection.frequentlyCited, false);
    setFilteredResults(SearchResultSection.library, false);
  }, [query]);

  return useRecoilValue(hasFilteredResultsState(section));
};

export const useHasFilteredResultsState = (
  Section: SearchResultSection,
): [boolean, (Section: SearchResultSection, value: boolean) => void] => {
  const results = useHasFilteredResults(Section);
  const setFilteredResults = useSetHasFilteredResults();

  return [results, setFilteredResults];
};
