import { isEqual, mapValues, take } from 'lodash';
import { atomFamily, selector, selectorFamily, useRecoilValue } from 'recoil';

import { resultsState } from '@/ext/app/state/searchResult';
import {
  SearchResultContentKey,
  searchResultContentSelector,
  StaticContentTypes,
} from '@/ext/app/state/searchResultContent';
import {
  searchResultSelectedSelector,
  selectedSearchResultState,
} from '@/ext/app/state/selectedSearchResult';
import { SearchResult } from '@/ext/app/state/types';
import {
  datapointForSearchResult,
  hasMoreThanCardinalTokens,
  hasMoreThanDateTokens,
} from './utils';
import {
  DatapointGroup,
  DatapointResult,
} from '@/components/pages/DatapointsPage/types';

/**
 * atomFamily for storing the grouped datapoints for a search result.
 * */
export const datapointGroupState = atomFamily<
  DatapointGroup | undefined,
  string
>({
  key: 'datapointGroupState',
  default: undefined,
});

/**
 * atomFamily for storing the conflicting datapoints for a search result.
 * */
export const datapointConflictingState = atomFamily<
  DatapointGroup | undefined,
  string
>({
  key: 'datapointConflictingState',
  default: undefined,
});

/**
 * Selector that returns all datapoints that have the follow criteria:
 * - a distance greater than 0.75
 * - have more than just cardinal tokens OR are in the list of cardinals
 */
export const datapointResults = selector<DatapointResult[]>({
  key: 'datapointResults',
  get: ({ get }) => {
    const results = get(resultsState)
      .flatMap((searchResult) => {
        const g = (
          url: string,
          contentType: SearchResultContentKey['contentType'] = 'dataPoints',
        ) =>
          get(
            searchResultContentSelector({
              props: { contentType },
              url,
            }),
          )?.data;

        const formatDatapointGroups = (datapointGroups: {
          [url: string]: number[];
        }) =>
          mapValues(datapointGroups, (idxs: number[], url) => {
            const datapoints = g(url)?.dataPoints;

            return idxs.map((idx) => datapoints?.[idx]!);
          });

        const cardinals =
          g(searchResult.url, StaticContentTypes.CARDINALS)?.cardinals || [];

        const datapoints = g(searchResult.url)
          ?.dataPoints?.map((datapoint, index) => ({
            datapoint,
            index,
          }))
          .filter(
            ({ datapoint: { matchTokens, distance } }, i) =>
              distance >= 0.75 &&
              (hasMoreThanCardinalTokens(matchTokens) || cardinals.includes(i)),
          )
          ?.map<DatapointResult>(({ datapoint, index }) => {
            const duplicates = get(
              datapointGroupState(`${searchResult.url}:${index}`),
            );
            const conflicts = get(
              datapointConflictingState(`${searchResult.url}:${index}`),
            );

            return {
              ...datapoint,
              conflicts: conflicts && formatDatapointGroups(conflicts),
              duplicates: duplicates && formatDatapointGroups(duplicates),
              searchResult,
            };
          });

        return datapoints;
      })
      .filter((d): d is DatapointResult => !!d);

    return results;
  },
});

/**
 * Selector that returns a result count for each domain / source.
 */
export const datapointSourceCount = selectorFamily<number, string>({
  key: 'datapointSourceCount',
  get:
    (source) =>
    ({ get }) => {
      const results = get(resultsState);

      return results.filter(({ domain }) => domain === source).length;
    },
});

export const useDatapointSourceCount = (source: string) =>
  useRecoilValue(datapointSourceCount(source));

/**
 * A selector to fetch the index of a search result.
 */
export const datapointSourceIndex = selectorFamily<
  number,
  Readonly<SearchResult>
>({
  key: 'datapointSourceIndex',
  get:
    (searchResult) =>
    ({ get }) =>
      get(resultsState).findIndex((r) => isEqual(r, searchResult)),
});

export const useDatapointSourceIndex = (searchResult: SearchResult) =>
  useRecoilValue(datapointSourceIndex(searchResult));

/**
 * List options for our datapoint results.
 */
export type ListTypesKey = 'top' | 'all';
export const listTypeOptions: { label: string; value: ListTypesKey }[] = [
  { label: 'Top', value: 'top' },
  { label: 'All', value: 'all' },
];
export const DEFAULT_LIST_TYPE = listTypeOptions[0];

/**
 * A selector to fetch the "top" search results.
 *
 * First get all search results that have a datapoint in their `description`.
 * Then find all datapoints we retreive that match the following criteria:
 * - Anything that's grouped OR in conflict OR...
 * - Up to 2 datapoints for each source that match the following criteria:
 * - The lowest distances
 * - distance is > 0.85
 * - have more than just date
 * - Do not have same entity/number pairs as another datapoint (count as 1, skip second)
 */
export const topDatapointResults = selector<DatapointResult[]>({
  key: 'topDatapointResults',
  get: ({ get }) => {
    const datapoints = get(datapointResults);
    const searchResultDatapoints = get(resultsState)
      .map((searchResult) => {
        const datapoint = datapointForSearchResult(datapoints, searchResult);

        if (!datapoint) {
          return;
        }

        return {
          ...datapoint,
          searchResult,
        };
      })
      .filter((d): d is DatapointResult => !!d);

    const filtered = [...datapoints]
      .sort((a, b) => (a.distance > b.distance ? -1 : 1))
      .reduce<DatapointResult[]>((results, datapoint) => {
        const { distance, matchContextFull, matchTokens } = datapoint;

        if (
          // Compare only the `matchContextFull` to filter out since `datapointForDescription` can return a modified datapoint.
          !searchResultDatapoints.some((d) =>
            isEqual(matchContextFull, d?.matchContextFull),
          ) &&
          distance > 0.85 &&
          (datapoint.conflicts ||
            datapoint.duplicates ||
            (hasMoreThanDateTokens(matchTokens) &&
              results.filter((d) => d.searchResult === datapoint.searchResult)
                .length < 2))
        ) {
          return [...results, datapoint];
        }

        return results;
      }, []);

    return [...searchResultDatapoints, ...filtered];
  },
});

/**
 * Main selector for fetching results by list type.
 */
export const datapointResultList = selectorFamily<
  DatapointResult[],
  ListTypesKey
>({
  key: 'datapointResultList',
  get:
    (type) =>
    ({ get }) => {
      const results =
        type === 'top' ? get(topDatapointResults) : get(datapointResults);
      const selectedSearchResult = get(selectedSearchResultState);

      return results.filter(
        ({ searchResult }) =>
          !selectedSearchResult ||
          get(searchResultSelectedSelector(searchResult)),
      );
    },
});

export const useDatapointResultList = (type: ListTypesKey) =>
  useRecoilValue(datapointResultList(type));
