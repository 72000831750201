import { KeyboardEvent } from 'react';
import { useRecoilState } from 'recoil';
import { exploreQueryRaw } from '@/ext/app/state/search/exploreQuery';
import { EXPLORE_FORM_LIST_ITEM_ID } from '../ExploreFormFilterListItem/constants';
import { ExploreFormInputProps } from './types';
import { useSetColumnTemplateExpanded } from '@/modules/templates/columnTemplate';

interface Props extends ExploreFormInputProps {
  onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export const useExploreFormInputProps = (
  props: ExploreFormInputProps,
): Props => {
  const [query, setQuery] = useRecoilState(exploreQueryRaw);
  const setExpanded = useSetColumnTemplateExpanded();

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        document.getElementById(EXPLORE_FORM_LIST_ITEM_ID)?.focus();
        break;
      case 'ArrowUp': {
        e.preventDefault();
        const items = document.querySelectorAll(
          `#${EXPLORE_FORM_LIST_ITEM_ID}`,
        );
        (items[items.length - 1] as HTMLElement)?.focus();
        break;
      }
      case 'Tab':
        e.preventDefault();
        setQuery(props.autosuggest || query);
        break;
      case 'Escape':
        setExpanded(false);
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  return {
    onKeyDown: handleKeyDown,
    ...props,
  };
};
