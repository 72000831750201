import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';

import { ExploreFormInputProps } from './types';
import { useExploreFormInputProps } from './useExploreFormInputProps';

export const AUTOCOMPLETE_CHARACTER = '⇥';

export const EXPLORE_INPUT_ID = 'explore-input';

const SearchField = styled(TextField)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: '100%',
  marginBottom: theme.spacing(1),

  '.MuiInputBase-formControl': {
    color: 'text.high',
    height: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.button,
  },
}));

const Form = styled('form')(() => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 1,
}));

const AutoSuggest = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: theme.spacing(0.78125),
  paddingLeft: theme.spacing(1),
  opacity: 0.5,
  pointerEvents: 'none',
}));

const ExploreFormInput: FunctionComponent<ExploreFormInputProps> = (props) => {
  const {
    onKeyDown,
    control,
    value,
    autosuggest,
    isValid,
    onClear,
    onChange,
    onSubmit,
  } = useExploreFormInputProps(props);

  return (
    <Form onSubmit={onSubmit}>
      <Controller
        name="search"
        control={control}
        render={(field) => (
          <Box position="relative">
            <SearchField
              {...field}
              data-component="ExploreFormInput"
              variant="outlined"
              color="primary"
              placeholder="Type to find a filter"
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={value}
              InputProps={{
                autoComplete: 'off',
                id: EXPLORE_INPUT_ID,
                endAdornment: (
                  <Box display="flex" gap={1}>
                    {value && (
                      <Button variant="text" onClick={onClear}>
                        <Typography variant="button" color="text.disabled">
                          Cancel
                        </Typography>
                      </Button>
                    )}
                    {isValid && (
                      <Button variant="text" onClick={onSubmit}>
                        <Typography variant="button" color="primary.main">
                          Apply
                        </Typography>
                      </Button>
                    )}
                  </Box>
                ),
              }}
            />
            {autosuggest && (
              <AutoSuggest display="flex">
                <Typography variant="button" color="text.disabled">
                  {`${autosuggest}${AUTOCOMPLETE_CHARACTER}`}
                </Typography>
              </AutoSuggest>
            )}
          </Box>
        )}
      />
    </Form>
  );
};

export default ExploreFormInput;
