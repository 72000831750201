import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { useCallback, useMemo } from 'react';
import { FilterOption } from '../types/filterOption';
import { FilterGroup } from '../types/filterGroup';
import {
  getKeywordSearchQueryKey,
  KeywordsAPI,
} from '@/ext/app/state/keywords';
import { useSearchQueryId } from '@/ext/app/state/searchResult';
import { useTopEntities } from '@/ext/app/state/topEntities';
import { useExploreQuery } from '../exploreQuery';
import ExploreFormXrayFilterListItem, {
  ExploreFormXrayFilterListItemProps,
} from '@/components/organisms/ExploreForm/ExploreFormXrayFilterListItem';
import { useUser } from '../../user';
import { Subscription } from '@/ext/types';

export const XRAY_GROUP_NAME = 'xray';

const formatter = ({
  value,
  mentions,
  pages,
}: {
  value: string;
  mentions?: number;
  pages?: number;
}) => ({
  value,
  label: value,
  groupName: XRAY_GROUP_NAME,
  FilterComponent: (props: ExploreFormXrayFilterListItemProps) => (
    <ExploreFormXrayFilterListItem
      {...props}
      pages={pages || 0}
      mentions={mentions || 0}
    />
  ),
});

const api = new KeywordsAPI();

const DEFAULT_OPTIONS: FilterOption[] = [
  { value: 'nike', label: 'nike', groupName: XRAY_GROUP_NAME, blurred: true },
  { value: 'hilt', label: 'hilt', groupName: XRAY_GROUP_NAME, blurred: true },
  {
    value: 'sustainability',
    label: 'sustainability',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
  {
    value: 'armour',
    label: 'armour',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
  {
    value: 'bandier',
    label: 'bandier',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
  { value: 'vuorl', label: 'vuorl', groupName: XRAY_GROUP_NAME, blurred: true },
  {
    value: 'alo yoga',
    label: 'alo yoga',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
  {
    value: 'sportswear',
    label: 'sportswear',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
  {
    value: 'lorem ipsum',
    label: 'lorem ipsum',
    groupName: XRAY_GROUP_NAME,
    blurred: true,
  },
];

export const useKeywordSearchInfinite = (
  searchQueryId: string,
  match?: string,
  limit?: number,
): UseInfiniteQueryResult<FilterOption[], Error> => {
  const entities = useTopEntities();
  const user = useUser();

  return useInfiniteQuery(
    [
      'infinite',
      ...getKeywordSearchQueryKey(searchQueryId, match, limit),
      entities,
    ],
    async ({ pageParam }) => {
      if (user?.subscription === Subscription.lite) {
        return DEFAULT_OPTIONS;
      }

      const data = await api.keywords({ searchQueryId, match, limit });
      // until we combine the top entities with the keyword search results
      if (!pageParam) {
        return [...entities, ...data].map(formatter);
      }
      return data.map(formatter);
    },
    {
      getNextPageParam: () => undefined,
    },
  );
};

const filter: Omit<FilterGroup, 'query'> = {
  name: 'X-Ray',
  id: XRAY_GROUP_NAME,
  tooltip:
    'Pinpoint specific text or phrases inside all your results at once – like a super “ctrl+F”.',
  multi: true,
};

export const useXrayFilterGroup = () => filter;

export const useXrayFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useXrayFilterGroup();
  const searchQueryId = useSearchQueryId();
  const query = useExploreQuery();
  const options = useKeywordSearchInfinite(searchQueryId, query);
  const getExpandLabel = useCallback(
    (num) => `See all ${num} x-ray options`,
    [],
  );
  const user = useUser();

  /**
   * This hack is to force the xray loading state to show up while we
   * are fetching data over the websocket
   */
  const queryOptions = useMemo(
    () =>
      options.data?.pages[0].length
        ? options
        : { isLoading: true, data: { pages: [{ id: -1, label: '' }] } },
    [options],
  ) as UseInfiniteQueryResult<FilterOption[], unknown>;

  return {
    ...filterGroup,
    getExpandLabel,
    query: queryOptions,
    disabled: user?.subscription === Subscription.lite,
  };
};
