import { isEqual } from 'lodash';
import {
  atom,
  SetterOrUpdater,
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  selectorFamily,
} from 'recoil';

import { SearchResult } from './types';

export type ValueType = SearchResult | undefined;

export const selectedSearchResultState = atom<ValueType>({
  key: 'selectedSearchResult',
  default: undefined,
});

export const useSelectedSearchResult = (): ValueType =>
  useRecoilValue(selectedSearchResultState);

export const useSetSelectedSearchResult = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(selectedSearchResultState);

export const useSelectedSearchResultState = (): [
  ValueType,
  SetterOrUpdater<ValueType>,
] => useRecoilState(selectedSearchResultState);

export const searchResultSelectedSelector = selectorFamily<
  boolean,
  Readonly<SearchResult>
>({
  key: 'searchResultSelected',
  get:
    (searchResult: SearchResult) =>
    ({ get }) => {
      const selectedValue = get(selectedSearchResultState);
      return isEqual(selectedValue, searchResult);
    },
});

export const useIsSearchResultSelected = (
  searchResult: SearchResult,
): boolean => useRecoilValue(searchResultSelectedSelector(searchResult));
