import { AutoAwesome } from '@mui/icons-material';
import { Box, Button, styled, Typography } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ThemeProvider, useTheme } from '@mui/system';
import capitalize from 'lodash/fp/capitalize';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import CtaLabel from '@/components/atoms/CtaLabel';
import { Subscription } from '@/ext/types';
import light from '@/styles/themes/light';
import { useSetDialog } from '@/ext/app/state/dialog';
import { useUser } from '@/ext/app/state/user';

export interface UpgradeButtonProps {
  className?: string;
  subscription?: string;
  variant: 'primary' | 'secondary' | 'tertiary';
  href?: string;
  onClick?: () => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const UpgradeIcon = styled(AutoAwesome)(({ theme }) => ({
  fontSize: theme.spacing(0.75),
  marginRight: theme.spacing(0.25),
}));

const UpgradeButton: FunctionComponent<UpgradeButtonProps> = ({
  subscription,
  variant = 'primary',
  className,
  href,
  onClick,
}) => {
  const theme = useTheme(light);
  const setDialog = useSetDialog();
  const { push } = useRouter();
  const user = useUser();

  const isTrial = useMemo(
    () => !user?.subscriptionEndDate && dayjs().diff(user?.trialEndDate) < 0,
    [user?.subscriptionEndDate, user?.trialEndDate],
  );

  const handleOpenDialog = () => {
    if (href) {
      push(href);
    } else {
      setDialog({ dialog: 'pricingTable' });
    }
    onClick?.();
  };

  switch (variant) {
    case 'primary': {
      let color = 'text.disabled';
      switch (subscription) {
        case Subscription.pro:
          color = 'warning.main';
          break;
        case Subscription.ultra:
          color = 'warning.light';
          break;
        case Subscription.enterprise:
          color = 'text.disabled';
          break;
        default:
          break;
      }

      return (
        <StyledButton
          className={className}
          variant="outlined"
          onClick={handleOpenDialog}
          fullWidth
        >
          <Box component="span" display="flex" alignItems="center">
            <Typography
              component="span"
              variant="subtitle2"
              marginRight={0.25}
              color="text.primary"
            >
              Waldo
            </Typography>
            <Typography component="span" variant="subtitle2" color={color}>
              {capitalize(subscription || '')}
            </Typography>
            {isTrial && (
              <Typography
                component="span"
                variant="subtitle2"
                color="text.helper"
                marginLeft={0.25}
              >
                Trial
              </Typography>
            )}
            {(subscription === Subscription.ultra ||
              subscription === Subscription.enterprise) && (
              <Typography
                color={color}
                marginLeft={0.25}
                display="flex"
                alignItems="center"
              >
                <AutoAwesome fontSize="small" color="inherit" />
              </Typography>
            )}
            {subscription !== Subscription.ultra &&
              subscription !== Subscription.enterprise && (
                <Box
                  component="span"
                  marginLeft={0.675}
                  display="flex"
                  alignItems="center"
                >
                  <AutoAwesome fontSize="small" color="primary" />
                  <Typography
                    component="span"
                    marginLeft={0.25}
                    variant="subtitle2"
                    color="primary"
                  >
                    Upgrade
                  </Typography>
                </Box>
              )}
          </Box>
        </StyledButton>
      );
    }

    case 'tertiary': {
      return (
        <CtaLabel
          onClick={handleOpenDialog}
          color="infoDark"
          paddingX={0.125}
          className={className}
        >
          <Typography component="span" color="white">
            <UpgradeIcon color="inherit" />
          </Typography>
        </CtaLabel>
      );
    }

    default: {
      return (
        <ThemeProvider theme={theme}>
          <CtaLabel
            onClick={handleOpenDialog}
            paddingX={0.5}
            paddingY={0.125}
            className={className}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              display="flex"
              alignItems="center"
            >
              <UpgradeIcon color="inherit" />
              Upgrade
            </Typography>
          </CtaLabel>
        </ThemeProvider>
      );
    }
  }
};

export default UpgradeButton;
