export const SEARCH_PARAM_KEY = 'waldo-d';
export const dialogs = <const>[
  'browser',
  'createTeam',
  'feedback',
  'firstTeam',
  'hotkeys',
  'inviteFriends',
  'lens',
  'lensDelete',
  'manageTeams',
  'onboardingQuestions',
  'powerSearch',
  'publishNote',
  'searchEngineMessage',
  'tutorialExit',
  'tutorials',
  'usabilitySettings',
  'userDeleteAccount',
  'userDeleteAccountSuccess',
  'userPasswordChange',
  'userProfile',
  'userTeams',
  'wonder',
  'googleDocsExport',
  'pricingTable',
];
export const userDialogs = [
  'tutorials',
  'createTeam',
  'firstTeam',
  'userTeams',
  'manageTeams',
  'inviteFriends',
  'userProfile',
  'userDeleteAccount',
  'userDeleteAccountSuccess',
  'userPasswordChange',
  'lens',
  'lensDelete',
  'feedback',
  'googleDocsExport',
];
