import { useCallback } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { FilterGroup } from '../types/filterGroup';
import { FilterOption } from '../types/filterOption';

export const fileTypeOptions = [
  { label: 'pdf', value: 'pdf', groupName: 'filetype' },
  {
    label: 'xls + xlsx',
    value: 'xls,xlsx',
    groupName: 'filetype',
  },
  {
    label: 'ppt + pptx',
    value: 'ppt,pptx',
    groupName: 'filetype',
  },
  {
    label: 'doc + docx',
    value: 'doc,docx',
    groupName: 'filetype',
  },
  {
    label: 'odt + odp + ods',
    value: 'odt,odp,ods',
    groupName: 'filetype',
  },
  {
    label: 'html',
    value: 'html,htm',
    groupName: 'filetype',
  },
  {
    label: 'txt + text',
    value: 'txt,text',
    groupName: 'filetype',
  },
  { label: 'rtf', value: 'rtf', groupName: 'filetype' },
  { label: 'bas', value: 'bas', groupName: 'filetype' },
  { label: 'c', value: 'c', groupName: 'filetype' },
  { label: 'cc', value: 'cc', groupName: 'filetype' },
  { label: 'cpp', value: 'cpp', groupName: 'filetype' },
  { label: 'cs', value: 'cs', groupName: 'filetype' },
  { label: 'cxx', value: 'cxx', groupName: 'filetype' },
  { label: 'h', value: 'h', groupName: 'filetype' },
  { label: 'hpp', value: 'hpp', groupName: 'filetype' },
  { label: 'java', value: 'java', groupName: 'filetype' },
  { label: 'dwf', value: 'dwf', groupName: 'filetype' },
  { label: 'kml', value: 'kml', groupName: 'filetype' },
  { label: 'pl', value: 'pl', groupName: 'filetype' },
  { label: 'ps', value: 'ps', groupName: 'filetype' },
  { label: 'py', value: 'py', groupName: 'filetype' },
  { label: 'tex', value: 'tex', groupName: 'filetype' },
  { label: 'wap', value: 'wap', groupName: 'filetype' },
  { label: 'wml', value: 'wml', groupName: 'filetype' },
  { label: 'xml', value: 'xml', groupName: 'filetype' },
];

const useFileTypeFilterGroupOptions = (): UseInfiniteQueryResult<
  FilterOption[],
  Error
> =>
  useInfiniteQuery(['file-type-filter-group-options'], () => fileTypeOptions, {
    getNextPageParam: undefined,
  });

const filter: Omit<FilterGroup, 'query'> = {
  name: 'File Type',
  id: 'filetype',
  tooltip: 'Only show results that contain a certain filetype.',
  multi: true,
};

export const useFileTypeFilterGroup = () => filter;

export const useFileTypeFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useFileTypeFilterGroup();
  const options = useFileTypeFilterGroupOptions();
  const getExpandLabel = useCallback((num) => `See all ${num} filetypes`, []);

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
