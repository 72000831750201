import {
  atom,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { sessionStorageEffect } from '@/ext/app/utils/recoil';

export const columnTemplateExpandedState = atom<boolean>({
  key: 'columnTemplateExpandedState',
  default: false,
  effects: [sessionStorageEffect],
});

export const useColumnTemplateExpandedState = (): [
  boolean,
  SetterOrUpdater<boolean>,
] => useRecoilState(columnTemplateExpandedState);

export const useSetColumnTemplateExpanded = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(columnTemplateExpandedState);

export const useColumnTemplateExpanded = (): boolean =>
  useRecoilValue(columnTemplateExpandedState);
