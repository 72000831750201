import { range } from 'lodash/fp';
import {
  atomFamily,
  noWait,
  selector,
  selectorFamily,
  useRecoilValue,
  waitForNone,
} from 'recoil';

import { bingState } from './bing';
import { SearchEngine } from './engine';
import { googleState } from './google';
import { Page } from './types';
import {
  numPagesState,
  searchQueryState,
  waldoSearchQueryFiltersState,
} from './waldoQuery';

/**
 * Engine override
 */
export const engineOverrideState = selector<SearchEngine>({
  key: 'engineOverrideState',
  get: ({ get }) => {
    const state = get(searchQueryState);
    const { lens } = get(waldoSearchQueryFiltersState);
    const { engine, searchQuery } = state;
    const numWords = searchQuery
      .split(' ')
      .filter((word) => /\w/.test(word))
      .filter((word) => word !== 'OR').length;
    const useBing = engine === 'bing' || (numWords >= 32 && lens?.length > 0);

    return useBing ? 'bing' : 'google';
  },
});

/**
 * Page state
 */
export const pageState = atomFamily<Page, number>({
  key: 'pageState',
  dangerouslyAllowMutability: true,
  default: selectorFamily<Page, number>({
    key: 'defaultPageState',
    dangerouslyAllowMutability: true,
    get:
      (page: number) =>
      ({ get }) => {
        const state = get(searchQueryState);
        const useBing = get(engineOverrideState) === 'bing';
        const searchQueryPage = { ...state, page };
        const result = useBing
          ? get(bingState(searchQueryPage))
          : get(googleState(searchQueryPage));

        return result;
      },
  }),
});

/**
 * Pages state
 *
 * - only returns the pages that are currently available
 */
export const pagesState = selector<Page[]>({
  key: 'pagesState',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const numPages = get(numPagesState);
    const pages = range(0, numPages).map((page) => pageState(page));

    return get(waitForNone(pages))
      .filter(({ state }) => state === 'hasValue')
      .map(({ contents }) => contents);
  },
});

/**
 * Page loading state
 */
export const pageLoadingState = selector<boolean>({
  key: 'pageLoadingState',
  get: ({ get }) => get(noWait(pageState(0))).state === 'loading',
});

export const useIsLoading = (): boolean => useRecoilValue(pageLoadingState);
