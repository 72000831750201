import { last } from 'lodash';

import { MatchToken } from '@/ext/app/state/searchResultContent';
import { SearchResult } from '@/ext/app/state/types';
import { DatapointResult } from '@/components/pages/DatapointsPage/types';

/**
 * Remove characters that can conflict when comparing match values.
 */
export const cleanedMatch = (match: string): string =>
  match.replace('...', '').replace(/[’'\s]/g, '');

/**
 * Check if a set of `matchTokens` have more than just cardinal tokens.
 */
export const hasMoreThanCardinalTokens = (matchTokens: MatchToken[]): boolean =>
  matchTokens.some(([, type]) =>
    ['MONEY', 'QUANTITY', 'PERCENT'].includes(type),
  );

/**
 * Check if a set of `matchTokens` have more than just date tokens.
 */
export const hasMoreThanDateTokens = (matchTokens: MatchToken[]): boolean =>
  matchTokens.some(([, type]) => type !== 'DATE');

/**
 * Check if a string contains any numbers that are not just dates (years).
 */
export const hasMoreThanDateNumbers = (text: string): boolean => {
  const numbers = text?.match(/\d+/g);
  const years = numbers?.filter(
    (x) => x.length === 4 && Number(x) > 1900 && Number(x) < 2100,
  );

  return (
    !!numbers && numbers.length > 0 && (!years || numbers.length > years.length)
  );
};

/**
 * Get a text value from an HTML string.
 */
export const textFromHTML = (html: string): string =>
  new DOMParser().parseFromString(html, 'text/html').body.textContent || '';

/**
 * Find the datapoint for the given `searchResult` if it `hasMoreThanDateNumbers`.
 */
export const datapointForSearchResult = (
  datapoints: DatapointResult[],
  searchResult: SearchResult,
): DatapointResult | undefined => {
  const description =
    searchResult.description ||
    textFromHTML(searchResult.descriptionHTML || '');

  if (!hasMoreThanDateNumbers(description)) {
    return;
  }

  const descriptionDatapoints = datapoints.filter((datapoint) => {
    if (!description) {
      return false;
    }

    const d = cleanedMatch(description);
    const f = cleanedMatch(datapoint.matchContextFull);
    const m = cleanedMatch(datapoint.match);

    return d.includes(m) || m.includes(d) || d.includes(f) || f.includes(d);
  });

  const { descriptionHTML } = searchResult;

  // Default to a temp `Match` so we can display something as soon as possible.
  const datapoint = last(descriptionDatapoints) || {
    distance: 0,
    match: description,
    matchMarkdown: descriptionHTML || '',
    matchStart: description,
    matchEnd: '',
    matchContextAfter: '',
    matchContextFull: '',
    matchContextFullMarkdown: '',
    matchContextAfterMarkdown: '',
    matchTokens: [],
  };

  return {
    ...datapoint,
    match: description || datapoint.match,
    matchMarkdown: descriptionHTML || datapoint.matchMarkdown,
    searchResult,
  };
};
